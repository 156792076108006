import { useMemo } from 'react';
import Select from 'react-select';

import { buildReactSelectStyles } from '../../utils/react-select';
import type { Option } from '../common/Utilities';

export function SelectEnum<T>(props: {
  options: Option<T>[];
  selected: T | null;
  invalid?: boolean;
  onChange: (value: T | null) => void;
  disabled?: boolean;
}): JSX.Element {
  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<T>>({
        isError: props.invalid ?? false,
      }),
    [props.invalid]
  );
  const selected = useMemo(() => {
    return props.options.find((o) => o.value === props.selected) ?? null;
  }, [props.options, props.selected]);

  return (
    <Select<Option<T>, false>
      classNamePrefix='select-box-v2'
      className='w-full text-xs'
      styles={styles}
      value={selected}
      options={props.options}
      onChange={(o) => props.onChange(o?.value ?? null)}
      isSearchable={false}
      isDisabled={props.disabled}
    />
  );
}
