import { useNavigate } from '@remix-run/react';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import useSWR from 'swr';
import { match } from 'ts-pattern';

import {
  type DtoProduct,
  type DtoProductFeature,
  EnumsProductBillingInterval,
  EnumsProductStatus,
  type ModelsPrice,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { err2s, uuidv4 } from '../../utils/common';
import { formatCurrency } from '../../utils/currency';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { ArchiveIcon } from '../icons/ArchiveIcon';
import { FilledCheckIcon } from '../icons/CheckIcon';
import { DeleteIcon } from '../icons/DeleteIcon';
import { LPLogo } from '../icons/LPLogo';
import { NewWindowIcon } from '../icons/NewWindowIcon';
import { PlusIcon } from '../icons/PlusIcon';
import { Loading } from '../Loading';
import { ProductFeatureEditor } from './ProductFeatureEditor';
import { SelectEnum } from './SelectEnum';
import { ProductUtils } from './utils';

function Nav(props: {
  onCancel: () => void;
  onSave: (data: ProductFormData) => Promise<void>;
}) {
  const { onCancel, onSave } = props;
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const {
    call: submit,
    state: { state, error },
    reset,
  } = useLiveAsyncCall(onSave);

  const {
    handleSubmit,
    formState: { isSubmitSuccessful, isDirty },
  } = useFormContext<ProductFormData>();

  const handleCancel = async () => {
    if (!isDirty) {
      onCancel();
      return;
    }

    const { result } = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>Are you sure?</ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            You have unsaved changes. Are you sure you want to discard them?
          </ConfirmCancelModalText>
        </div>
      ),
      cancelBtnLabel: 'Keep editing',
      confirmBtnLabel: 'Discard changes',
      confirmBtnVariant: 'delete',
    });
    if (result === 'confirmed') {
      onCancel();
    }
  };

  return (
    <nav className='w-full flex-shrink-0'>
      <div className='w-full h-20 px-5 bg-admin-red border-secondary border-b flex items-center justify-between'>
        <div className='flex items-center gap-4'>
          <LPLogo type='admin' />
          <div className='text-1.5xl font-medium text-white'>Edit Product</div>
        </div>
        <div className='flex items-center gap-5'>
          <p className='text-icon-gray text-sms'>
            {isSubmitSuccessful && !isDirty && (
              <div className='flex items-center gap-1'>
                <FilledCheckIcon />
                Saved
              </div>
            )}
          </p>

          <button
            type='button'
            onClick={handleCancel}
            disabled={state.isRunning}
            className='btn-secondary w-33 h-10'
          >
            {isDirty ? 'Cancel' : 'Close'}
          </button>
          <button
            type='button'
            onClick={handleSubmit(submit)}
            disabled={state.isRunning || !isDirty}
            className='btn-primary w-33 h-10'
          >
            {state.isRunning ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>

      {error && (
        <div className='w-full px-5 py-2 text-base flex gap-1 bg-lp-gray-001'>
          <p className='text-red-005'>Error: {err2s(error)}</p>
          <button
            type='button'
            onClick={() => reset()}
            className='btn self-end text-primary'
          >
            Clear
          </button>
        </div>
      )}
    </nav>
  );
}

function ProductStatus(props: {
  status: DtoProduct['status'] | undefined | null;
}) {
  const status = match(props.status)
    .with(EnumsProductStatus.ProductStatusArchived, () => 'Archived')
    .with(EnumsProductStatus.ProductStatusDraft, () => 'Draft')
    .with(EnumsProductStatus.ProductStatusPublished, () => 'Published')
    .otherwise(() => 'Draft');

  const statusColor = match(props.status)
    .with(
      EnumsProductStatus.ProductStatusArchived,
      () => 'bg-red-003 text-white'
    )
    .with(
      EnumsProductStatus.ProductStatusDraft,
      () => 'bg-tertiary text-black text-opacity-75'
    )
    .with(
      EnumsProductStatus.ProductStatusPublished,
      () => 'bg-green-001 text-black text-opacity-75'
    )
    .otherwise(() => 'bg-secondary');

  return (
    <div className={`${statusColor} rounded-full px-3 py-1 text-xs font-bold`}>
      {status}
    </div>
  );
}

function ProductHeader(props: { product: DtoProduct }) {
  const { product } = props;
  const { watch } = useFormContext<ProductFormData>();
  const name = watch('name');
  return (
    <header className='px-8 py-12 space-y-6'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-2'>
          <div className='text-white text-3xl font-bold'>{name}</div>
          <ProductStatus status={product.status} />
        </div>
        <div>
          <DeleteButton product={product} />
        </div>
      </div>
      <div className='flex items-start gap-6'>
        <div className='flex flex-col gap-1'>
          <div className='text-icon-gray tracking-wide text-xs'>Created</div>
          <div className='text-white text-sms'>
            {format(new Date(product.createdAt), 'MMM dd, yyyy')}
          </div>
        </div>

        <div className='flex flex-col gap-1'>
          <div className='text-icon-gray tracking-wide text-xs'>Updated</div>
          <div className='text-white text-sms'>
            {format(new Date(product.updatedAt), 'MMM dd, yyyy')}
          </div>
        </div>

        {product.stripeProductUrl && (
          <div className='flex flex-col gap-1'>
            <div className='text-icon-gray tracking-wide text-xs'>Stripe</div>
            <a
              className='text-white text-sms flex items-center gap-1 hover:underline'
              href={product.stripeProductUrl}
              target='_blank'
              rel='noreferrer noopener'
            >
              {product.stripeProductId}
              <NewWindowIcon />
            </a>
          </div>
        )}
      </div>
    </header>
  );
}

function DeleteButton(props: { product: DtoProduct }) {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const navigate = useNavigate();
  const {
    call: submit,
    state: { state },
  } = useLiveAsyncCall(async () => {
    await apiService.product.deleteProduct(props.product.id);
  });

  const handleDelete = async () => {
    const response = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>Delete Draft</ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            Are you sure you want to delete this draft? This action cannot be
            undone.
          </ConfirmCancelModalText>
        </div>
      ),
      confirmBtnLabel: 'Delete',
      confirmBtnVariant: 'delete',
    });
    if (response.result !== 'confirmed') return;

    await submit();
    navigate('/admin/products');
  };

  if (props.product.status !== EnumsProductStatus.ProductStatusDraft) {
    return null;
  }

  return (
    <button
      type='button'
      className='btn text-red-001 text-sms flex justify-center items-center'
      onClick={handleDelete}
      disabled={state.isRunning}
    >
      {state.isRunning ? (
        <Loading text='' imgClassName='w-5 h-5' />
      ) : (
        <>Delete</>
      )}
    </button>
  );
}

function FeaturesTable(props: {
  features: DtoProductFeature[];
  isLoading: boolean;
  error: unknown;
  onClick?: (feature: DtoProductFeature) => void;
}) {
  const { features, isLoading, error, onClick } = props;
  if (isLoading) {
    return (
      <div className='w-full py-6 flex items-center justify-center text-icon-gray'>
        <Loading text='' />
      </div>
    );
  }

  if (error) {
    return (
      <div className='w-full py-6 flex items-center justify-center text-icon-gray'>
        Failed to load product features. Try again.
      </div>
    );
  }

  return (
    <>
      {features.length === 0 ? (
        <div className='w-full py-6 flex items-center justify-center text-icon-gray'>
          No features
        </div>
      ) : (
        <table className='w-full table-auto border-collapse '>
          <thead>
            <tr>
              <th className='text-left font-bold pl-3 py-2 border-b border-secondary'>
                Name
              </th>
              <th className='text-left font-bold py-2 border-b border-secondary'>
                Type
              </th>
              <th className='text-left font-bold py-2 border-b border-secondary'>
                Description
              </th>
              <th className='text-left font-bold py-2 border-b border-secondary'>
                Created
              </th>
            </tr>
          </thead>
          <tbody>
            {features.map((feature) => (
              <tr
                key={feature.id}
                className='hover:bg-dark-gray cursor-pointer'
                onClick={() => onClick?.(feature)}
              >
                <td className='text-left font-bold pl-3 py-3 border-b border-black-001'>
                  {feature.name}
                </td>
                <td className='text-left text-icon-gray py-3 border-b border-black-001'>
                  {feature.type}
                </td>
                <td className='text-left text-icon-gray py-3 border-b border-black-001'>
                  {feature.description ?? ''}
                </td>
                <td className='text-left text-icon-gray py-3 border-b border-black-001'>
                  {format(new Date(feature.createdAt), 'MMM dd, yyyy')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}

const billingIntervalOptions = Object.values(EnumsProductBillingInterval).map(
  (t) => ({
    label: t.toString(),
    value: t,
  })
);

function PriceEditor(props: {
  title: string;
  btnLabel: string;
  initial?: ModelsPrice;
  onCancel: () => void;
  onSave: (price: ModelsPrice) => void;
}) {
  const [maxSeats, setMaxSeats] = useState<number | undefined>(
    props.initial?.maxSeats
  );
  const [amount, setAmount] = useState<number | undefined>(
    props.initial?.amount
  );
  const [interval, setInterval] = useState<EnumsProductBillingInterval>(
    props.initial?.billingInterval ??
      EnumsProductBillingInterval.ProductBillingIntervalMonthly
  );
  const isValid = useMemo(() => {
    return amount !== undefined || maxSeats !== undefined;
  }, [amount, maxSeats]);

  const handleSave = useLiveCallback(async () => {
    if (!isValid) return;
    props.onSave({
      id: props.initial?.id ?? uuidv4(),
      maxSeats: maxSeats ?? 0,
      billingInterval: interval,
      amount: amount ?? 0,
      archived: props.initial?.archived ?? false,
    });
  });

  return (
    <div className='border border-secondary bg-black rounded-xl px-5 py-3 w-142 min-h-45'>
      <div className='w-full h-full flex flex-col text-white'>
        <div className='flex-none w-full py-2'>
          <div className='font-bold text-2xl'>{props.title}</div>
        </div>

        <div className='flex-grow flex-shrink-0 w-full py-6 space-y-4'>
          <div className='grid grid-cols-2 items-start gap-x-6'>
            <div className='flex flex-col gap-1'>
              <div className='font-bold'>Max Seats</div>
              <div className='text-xs text-icon-gray'>
                Defines the maximum seats for the org.
              </div>
            </div>
            <input
              type='number'
              className='w-full h-10 field mb-0'
              autoComplete='off'
              placeholder='Enter max seats'
              defaultValue={maxSeats}
              onChange={(e) => setMaxSeats(e.target.valueAsNumber)}
              autoFocus
            />
          </div>

          <div className='grid grid-cols-2 items-start gap-x-6'>
            <div className='flex flex-col gap-1'>
              <div className='font-bold'>Amount</div>
              <div className='text-xs text-icon-gray'>
                The price of the product in USD.
              </div>
            </div>
            <div className='w-full flex flex-col items-end gap-2'>
              <input
                type='number'
                className='w-full h-10 field mb-0'
                autoComplete='off'
                placeholder='0.00'
                defaultValue={amount}
                onChange={(e) => setAmount(e.target.valueAsNumber)}
              />
              <div className='text-icon-gray text-xs'>
                Monthly Price:{' '}
                {amount && interval ? (
                  <>
                    {formatCurrency(
                      amount / ProductUtils.MonthlyInterval(interval)
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 items-start gap-x-6'>
            <div className='flex flex-col gap-1'>
              <div className='font-bold'>Billing Interval</div>
              <div className='text-xs text-icon-gray'>
                How often the product is billed.
              </div>
            </div>
            <SelectEnum
              options={billingIntervalOptions}
              selected={interval}
              onChange={(i) => {
                if (!i) return;
                setInterval(i);
              }}
            />
          </div>
        </div>

        <div className='mt-auto w-full pb-2 flex items-center justify-end gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 py-2'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 py-2'
            onClick={handleSave}
            disabled={!isValid}
          >
            {props.btnLabel}
          </button>
        </div>
      </div>
    </div>
  );
}

function PricingTable(props: { readOnly?: boolean; published?: boolean }) {
  const { readOnly, published } = props;
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const [showArchived, setShowArchived] = useState(false);
  const {
    fields: prices,
    append,
    remove,
    update,
  } = useFieldArray<ProductFormData, 'prices', 'key'>({
    name: 'prices',
    keyName: 'key',
  });

  const handleAddPrice = async () => {
    await triggerModal({
      kind: 'custom',
      element: (p) => {
        const handleSave = (price: ModelsPrice) => {
          append(price);
          p.internalOnConfirm();
        };
        return (
          <PriceEditor
            title='Add Price'
            btnLabel='Create'
            onCancel={p.internalOnCancel}
            onSave={handleSave}
          />
        );
      },
    });
  };

  const handleEditPrice = async (index: number, price: ModelsPrice) => {
    await triggerModal({
      kind: 'custom',
      element: (p) => {
        const handleSave = (price: ModelsPrice) => {
          update(index, price);
          p.internalOnConfirm();
        };
        return (
          <PriceEditor
            title='Edit Price'
            btnLabel='Save'
            initial={price}
            onCancel={p.internalOnCancel}
            onSave={handleSave}
          />
        );
      },
    });
  };

  const handleDeletePrice = (index: number | undefined, price: ModelsPrice) => {
    if (index === undefined) return;
    if (published) {
      update(index, { ...price, archived: true });
    } else {
      remove(index);
    }
  };

  const [groupedPrices, hasArchives] = useMemo(() => {
    const hasArchives = prices.some((p) => p.archived);
    let pricesWithIndex = prices.map((price, index) => ({ price, index }));
    if (!showArchived) {
      pricesWithIndex = pricesWithIndex.filter((p) => !p.price.archived);
    }

    // sort by price (ascending)
    pricesWithIndex.sort((a, b) => {
      return a.price.amount - b.price.amount;
    });

    // group activePrices by maxSeats
    const groupedPrices = pricesWithIndex.reduce((acc, p) => {
      const key = p.price.maxSeats;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(p);
      return acc;
    }, {} as Record<number, { price: ModelsPrice; index: number }[]>);

    return [groupedPrices, hasArchives];
  }, [prices, showArchived]);

  return (
    <div className='w-full'>
      <div className='w-full flex items-center justify-between border-b border-secondary'>
        <div className='text-1.5xl font-bold py-4'>Pricing</div>
        {!readOnly && (
          <button
            type='button'
            className='btn flex justify-center items-center text-primary gap-1 text-sm hover:underline'
            onClick={handleAddPrice}
          >
            <PlusIcon />
            Add Price
          </button>
        )}
      </div>

      {prices.length === 0 ? (
        <div className='w-full py-6 flex items-center justify-center text-icon-gray'>
          No prices
        </div>
      ) : (
        <table className='w-full table-fixed border-collapse '>
          <thead>
            <tr>
              <th className='w-2/12 text-left font-bold pl-3 py-2 border-b border-secondary'>
                Max Seats
              </th>
              <th className='w-5/12 text-left font-bold py-2 border-b border-secondary'>
                Price
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(groupedPrices).map(([maxSeats, prices]) => {
              return (
                <tr
                  key={`active-${maxSeats}`}
                  className={`
                    hover:bg-dark-gray
                  `}
                >
                  <td className='text-left py-3 pl-3 pr-6 border-b border-black-001'>
                    {maxSeats}
                  </td>
                  <td className='text-left py-3 border-b border-black-001'>
                    <div className='flex flex-wrap items-center gap-3'>
                      {prices.map(({ price, index }) => {
                        const disabled =
                          readOnly ||
                          Boolean(price.stripePriceId && published) ||
                          price.archived;

                        return (
                          <div
                            key={price.id}
                            className={`
                              flex items-center gap-2 bg-layer-002 px-3 py-1 rounded
                              ${price.archived ? 'opacity-60' : 'opacity-100'}
                              ${
                                disabled
                                  ? 'text-icon-gray'
                                  : 'text-white cursor-pointer'
                              }
                            `}
                            onClick={
                              disabled
                                ? undefined
                                : () => handleEditPrice(index, price)
                            }
                          >
                            <span
                              className={price.archived ? 'line-through' : ''}
                              title='Archive Price'
                            >
                              {ProductUtils.PriceString(price)}
                            </span>

                            <div className='ml-auto flex items-center gap-4'>
                              {!readOnly &&
                                (price.archived ? (
                                  <span className='text-3xs uppercase tracking-wide font-bold'>
                                    Archived
                                  </span>
                                ) : (
                                  <button
                                    type='button'
                                    className='btn text-red-001'
                                    onClick={() =>
                                      handleDeletePrice(index, price)
                                    }
                                  >
                                    {price.stripePriceId ? (
                                      <ArchiveIcon className='w-3 h-3 fill-current' />
                                    ) : (
                                      <DeleteIcon className='w-3 h-3 fill-current' />
                                    )}
                                  </button>
                                ))}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {hasArchives && (
        <div className='w-full pt-2'>
          <button
            type='button'
            className='btn text-primary text-sms'
            onClick={() => setShowArchived((prev) => !prev)}
          >
            {showArchived ? 'Hide Archived' : 'Show Archived'}
          </button>
        </div>
      )}
    </div>
  );
}

type ProductFormData = {
  name: string;
  description: string | null;
  prices: ModelsPrice[];
  trialPeriodDays: number | null;
};

function ProductForm(props: { readOnly?: boolean }) {
  const { readOnly } = props;
  const {
    formState: { errors },
    register,
    unregister,
    watch,
    setValue,
  } = useFormContext<ProductFormData>();

  const trialPeriodDays = watch('trialPeriodDays');

  return (
    <div className='w-full'>
      <div className='text-1.5xl font-bold py-4 border-b border-secondary'>
        Details
      </div>
      <div className='pt-4 w-full flex flex-col gap-6'>
        <label>
          <h2 className='text-base font-bold mb-1'>Name</h2>
          <input
            className={`w-full h-12.5 ${
              errors.name ? 'field-error' : 'field'
            } mb-0`}
            maxLength={50}
            autoComplete='off'
            placeholder='Must be 1 to 50 characters'
            {...register('name', {
              required: true,
              maxLength: 50,
            })}
            disabled={readOnly}
          />
          {errors.name && (
            <div className='w-full px-2 pt-1 text-left text-red-005 text-3xs h-3'>
              Must be 1 to 50 characters
            </div>
          )}
        </label>
        <label>
          <h2 className='text-base font-bold mb-1'>Description</h2>
          <textarea
            className={`w-full h-30 py-2 scrollbar ${
              errors.description ? 'field-error' : 'field'
            } mb-0`}
            maxLength={100}
            placeholder='Max 100 characters'
            {...register('description', {
              maxLength: 100,
            })}
            disabled={readOnly}
          ></textarea>
          {errors.description && (
            <div className='w-full px-2 pt-1 text-left text-red-005 text-3xs h-3'>
              Max 100 characters
            </div>
          )}
        </label>

        <div>
          <div className='flex items-center justify-between'>
            <h2 className='text-base font-bold mb-1'>Trial Period</h2>
            <button
              type='button'
              className={`
                btn text-sms flex items-center gap-1
                ${trialPeriodDays === null ? 'text-primary' : 'text-red-001'}
              `}
              onClick={() => {
                const next = trialPeriodDays === null ? 1 : null;
                if (next === null) {
                  unregister('trialPeriodDays');
                }
                setValue('trialPeriodDays', next, { shouldDirty: true });
              }}
            >
              {trialPeriodDays === null ? (
                <>
                  <PlusIcon className='w-3 h-3 fill-current' />
                  Add Trial
                </>
              ) : (
                <>
                  <DeleteIcon className='w-3 h-3 fill-current' />
                  Remove
                </>
              )}
            </button>
          </div>

          {trialPeriodDays !== null && (
            <>
              <div className='text-icon-gray text-sms mb-3'>
                Trial periods apply to all non-zero prices. Specified in number
                of days.
              </div>
              <div className='flex items-center gap-2'>
                <input
                  className={`w-40 h-10 ${
                    errors.trialPeriodDays ? 'field-error' : 'field'
                  } mb-0`}
                  type='number'
                  min={1}
                  autoComplete='off'
                  placeholder='0'
                  {...register('trialPeriodDays', {
                    valueAsNumber: true,
                    min: 1,
                    required: true,
                  })}
                  disabled={readOnly}
                />
                <span className='text-sms text-icon-gray'>Days</span>
              </div>
              {errors.trialPeriodDays && (
                <div className='w-full px-2 pt-1 text-left text-red-005 text-3xs h-3'>
                  Must be 1 or more
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export function ProductEditor(props: {
  productId: string;
  initialData: DtoProduct;
  onSaved?: (next: DtoProduct) => Promise<void>;
  onCancel: () => void;
}): JSX.Element {
  const { productId, initialData, onSaved, onCancel } = props;

  const featuresResponse = useSWR(
    ['product', props.initialData.id, 'features'],
    async ([_, id, __]) => {
      const r = await apiService.product.getProductFeatures(id);
      return r.data.productFeatures;
    },
    {
      fallbackData: props.initialData.features,
      shouldRetryOnError: false,
    }
  );

  const [featureEditorItem, setFeatureEditorItem] = useState<
    { item: DtoProductFeature | null } | undefined
  >();

  const formReturned = useForm<ProductFormData>({
    defaultValues: {
      name: initialData.name,
      description: initialData.description,
      prices: initialData.prices ?? [],
      trialPeriodDays: initialData.trialPeriodDays ?? null,
    },
  });
  const { reset } = formReturned;

  const handleAddFeature = () => {
    setFeatureEditorItem({ item: null });
  };

  const handleEditFeature = (feature: DtoProductFeature) => {
    setFeatureEditorItem({ item: feature });
  };

  const handleCloseFeatureEditor = () => {
    setFeatureEditorItem(undefined);
  };

  const handleSaveFeature = async () => {
    await featuresResponse.mutate();
    handleCloseFeatureEditor();
  };

  const handleSave = async (data: ProductFormData) => {
    const resp = await apiService.product.updateProduct(productId, {
      name: data.name,
      description: data.description ?? undefined,
      prices: data.prices,
      trialPeriodDays: data.trialPeriodDays ?? undefined,
    });
    const next = resp.data.product;
    await onSaved?.(next);
    reset({
      name: next.name,
      description: next.description,
      prices: next.prices ?? [],
      trialPeriodDays: next.trialPeriodDays ?? null,
    });
  };

  const readOnlyPricing =
    initialData.status === EnumsProductStatus.ProductStatusArchived;

  return (
    <FormProvider {...formReturned}>
      <div className='relative w-full h-full text-white flex flex-col'>
        <Nav onCancel={onCancel} onSave={handleSave} />

        <div className='w-full h-full overflow-y-scroll scrollbar'>
          <ProductHeader product={initialData} />

          <section className='px-8 flex flex-col gap-6 mb-6 lg:flex-row lg:mb-2'>
            <div className='w-full lg:w-1/2'>
              <ProductForm />
            </div>
            <div className='w-full lg:w-1/2'>
              <PricingTable
                readOnly={readOnlyPricing}
                published={
                  initialData.status ===
                  EnumsProductStatus.ProductStatusPublished
                }
              />
            </div>
          </section>

          <section className='px-8 pb-12.5'>
            <div className='flex items-center justify-between py-4 border-b border-secondary'>
              <div className='text-1.5xl font-bold'>Features</div>
              <button
                type='button'
                className='btn flex justify-center items-center text-primary gap-1 text-sm'
                onClick={handleAddFeature}
              >
                <PlusIcon />
                Add Feature
              </button>
            </div>
            <FeaturesTable
              features={featuresResponse.data}
              isLoading={featuresResponse.isLoading}
              error={featuresResponse.error}
              onClick={handleEditFeature}
            />
          </section>

          {featureEditorItem && (
            <div className='fixed inset-0'>
              <div className='absolute inset-0 bg-lp-black-001' />
              <div className='absolute top-0 bottom-0 right-0 w-1/2 bg-layer-001'>
                <ProductFeatureEditor
                  productId={productId}
                  initialData={featureEditorItem.item}
                  onCancel={handleCloseFeatureEditor}
                  onSave={handleSaveFeature}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </FormProvider>
  );
}
