import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';

import { ProductEditor } from '../components/Product/ProductEditor';
import { apiService } from '../services/api-service';
import { tokenWithRedirect } from '../utils/router';

export const loader = async (action: ClientLoaderFunctionArgs) => {
  const { id } = action.params;
  if (!id) throw new Response('Not Found', { status: 404 });

  const resp = await tokenWithRedirect(
    () => apiService.product.getProduct(id),
    action.request.url,
    { admin: true }
  );

  return resp.data;
};

function AdminProductEdit(): JSX.Element {
  const { product } = useLoaderData<typeof loader>();
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate('/admin/products');
  };

  return (
    <ProductEditor
      productId={product.id}
      initialData={product}
      onCancel={handleCancel}
    />
  );
}

export const Component = AdminProductEdit;
