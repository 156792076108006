import { Link } from '@remix-run/react';
import { useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import Select, { type SingleValue } from 'react-select';
import { usePrevious } from 'react-use';
import useSWR from 'swr';

import {
  type DtoProductFeature,
  type DtoProgram,
  EnumsLiveBookingAccess,
  EnumsOndGamePlayAccess,
  EnumsOndGameUGCAccess,
  EnumsProductFeatureType,
  EnumsProgramAccess,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useInstance } from '../../hooks/useInstance';
import { apiService } from '../../services/api-service';
import { type Tag } from '../../types';
import { type GamePack } from '../../types/game';
import { fromDTOGamePack } from '../../utils/api-dto';
import { err2s } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { GamePackSelect } from '../Game/GameCenter/GamePackSelect';
import { GamePackEditorUtils } from '../Game/GamePack/utils';
import { DeleteIcon } from '../icons/DeleteIcon';
import { NewWindowIcon } from '../icons/NewWindowIcon';
import { Loading } from '../Loading';
import { useActivePrograms } from '../Program/usePrograms';
import { TagPicker } from '../Tagging';
import { SelectEnum } from './SelectEnum';

export interface ProductFeatureFormData {
  type: EnumsProductFeatureType | null;
  name: string | null;
  description: string | null;
  featureSettings: DtoProductFeature['featureSettings'] | null;
}

function featureTypeDisplayName(type: EnumsProductFeatureType): string {
  switch (type) {
    case EnumsProductFeatureType.ProductFeatureTypeProgramAccess:
      return 'Programs Access';
    case EnumsProductFeatureType.ProductFeatureTypeOndGamePlay:
      return 'Ond Game Play';
    case EnumsProductFeatureType.ProductFeatureTypeLiveBooking:
      return 'Live Booking';
    case EnumsProductFeatureType.ProductFeatureTypeVenueSeatCap:
      return 'Venue Seat Cap';
    case EnumsProductFeatureType.ProductFeatureTypeOndGameUgc:
      return 'Ond Game UGC';
  }
}

const featureTypeOptions: Option<EnumsProductFeatureType>[] = Object.values(
  EnumsProductFeatureType
).map((t) => ({
  label: featureTypeDisplayName(t),
  value: t,
}));

function ProductFeatureForm(props: { readOnly?: boolean }) {
  const { readOnly } = props;
  const {
    formState: { errors },
    register,
  } = useFormContext<ProductFeatureFormData>();

  return (
    <div className='w-full flex flex-col gap-8'>
      <label>
        <h2 className='text-base font-bold mb-1'>Name</h2>
        <input
          className={`w-full h-12.5 ${
            errors.name ? 'field-error' : 'field'
          } mb-0`}
          maxLength={50}
          autoComplete='off'
          placeholder='Must be 1 to 50 characters'
          {...register('name', {
            required: true,
            maxLength: 50,
          })}
          disabled={readOnly}
        />
        {errors.name && (
          <div className='w-full px-2 pt-1 text-left text-red-005 text-3xs h-3'>
            Must be 1 to 50 characters
          </div>
        )}
      </label>
      <label>
        <h2 className='text-base font-bold mb-1'>Description</h2>
        <textarea
          className={`w-full h-30 py-2 scrollbar ${
            errors.description ? 'field-error' : 'field'
          } mb-0`}
          maxLength={100}
          placeholder='Max 100 characters'
          {...register('description', {
            maxLength: 100,
          })}
          disabled={readOnly}
        />
        {errors.description && (
          <div className='w-full px-2 pt-1 text-left text-red-005 text-3xs h-3'>
            Max 100 characters
          </div>
        )}
      </label>
      <Controller<ProductFeatureFormData, 'type'>
        name='type'
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <label>
            <h2 className='text-base font-bold mb-1'>Type</h2>
            <SelectEnum
              options={featureTypeOptions}
              selected={field.value}
              onChange={field.onChange}
              invalid={fieldState.invalid}
              disabled={readOnly}
            />
          </label>
        )}
      />
      <div className='border-b border-secondary w-full' />
      <FeatureSettingsForm readOnly={readOnly} />
    </div>
  );
}

function FeatureSettingsForm(props: { readOnly?: boolean }) {
  const { readOnly } = props;
  const { watch } = useFormContext<ProductFeatureFormData>();
  const type = watch('type');

  if (!type) return null;

  switch (type) {
    case EnumsProductFeatureType.ProductFeatureTypeLiveBooking:
      return <LiveBookingSettingsForm readOnly={readOnly} />;
    case EnumsProductFeatureType.ProductFeatureTypeProgramAccess:
      return <ProgramAccessSettingsForm readOnly={readOnly} />;
    case EnumsProductFeatureType.ProductFeatureTypeOndGamePlay:
      return <OndGamePlaySettingsForm readOnly={readOnly} />;
    case EnumsProductFeatureType.ProductFeatureTypeVenueSeatCap:
      return <VenueSeatCapSettingsForm readOnly={readOnly} />;
    case EnumsProductFeatureType.ProductFeatureTypeOndGameUgc:
      return <OndGameUGCSettingsForm readOnly={readOnly} />;
    default:
      return null;
  }
}

const liveBookingAccessOptions = Object.values(EnumsLiveBookingAccess).map(
  (t) => ({
    label: t.toString(),
    value: t,
  })
);

function LiveBookingSettingsForm(props: { readOnly?: boolean }) {
  const { readOnly } = props;
  const { register, watch } = useFormContext<ProductFeatureFormData>();
  const type = watch('featureSettings.liveBooking.access');

  return (
    <div className='w-full flex flex-col gap-8'>
      <div className='tracking-wide font-medium'>Live Booking Settings</div>
      <Controller<ProductFeatureFormData, 'featureSettings.liveBooking.access'>
        name='featureSettings.liveBooking.access'
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <label>
            <h2 className='text-base font-bold mb-1'>Access</h2>
            <SelectEnum
              options={liveBookingAccessOptions}
              selected={field.value}
              onChange={field.onChange}
              invalid={fieldState.invalid}
              disabled={readOnly}
            />
          </label>
        )}
      />
      {type === EnumsLiveBookingAccess.LiveBookingAccessDiscount && (
        <label>
          <h2 className='text-base font-bold mb-1'>Discount (%)</h2>
          <input
            type='number'
            className='w-full h-10 field mb-0'
            {...register(`featureSettings.liveBooking.discount`, {
              valueAsNumber: true,
            })}
            disabled={readOnly}
          />
        </label>
      )}
    </div>
  );
}

const programAccessOptions = Object.values(EnumsProgramAccess).map((t) => ({
  label: t.toString(),
  value: t,
}));

function ProgramAccessSettingsForm(props: { readOnly?: boolean }) {
  const { readOnly } = props;
  const { watch } = useFormContext<ProductFeatureFormData>();
  const type = watch('featureSettings.programAccess.access');

  return (
    <div className='w-full flex flex-col gap-8'>
      <div className='tracking-wide font-medium'>Programs Settings</div>
      <Controller<
        ProductFeatureFormData,
        'featureSettings.programAccess.access'
      >
        name='featureSettings.programAccess.access'
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <label>
            <h2 className='text-base font-bold mb-1'>Access</h2>
            <SelectEnum
              options={programAccessOptions}
              selected={field.value}
              onChange={field.onChange}
              invalid={fieldState.invalid}
              disabled={readOnly}
            />
          </label>
        )}
      />
      {type === EnumsProgramAccess.ProgramAccessProgramIds && <ProgramsTable />}
    </div>
  );
}

function ProgramPicker(props: {
  onSelect: (product: DtoProgram) => void;
  exclude?: DtoProgram[];
}): JSX.Element | null {
  const { onSelect } = props;

  const { data, isLoading, error } = useActivePrograms();
  const styles = useInstance(() => buildReactSelectStyles<DtoProgram>());
  const options = useMemo(() => {
    if (!data) return [];
    return (data ?? []).filter(
      (p) => !props.exclude?.find((e) => e.id === p.id)
    );
  }, [data, props.exclude]);

  const handleChange = (option: SingleValue<DtoProgram>) => {
    if (!option) return;
    onSelect(option);
  };

  if (isLoading) {
    return (
      <div className='w-full flex items-center justify-center'>
        <Loading text='' />
      </div>
    );
  }

  if (error) {
    return (
      <div className='w-full flex items-center justify-center'>
        <p className='text-red-001'>{err2s(error)}</p>
      </div>
    );
  }

  return (
    <Select<DtoProgram, false>
      placeholder='Add program'
      styles={styles}
      classNamePrefix='select-box-v2'
      className='w-1/4'
      value={null} // never set.
      options={options}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option?.name ?? 'Unnamed'}
      onChange={handleChange}
      isSearchable
    />
  );
}

function ProgramsTable(props: { readOnly?: boolean }): JSX.Element {
  const { readOnly } = props;

  const { getValues, setValue } = useFormContext<ProductFeatureFormData>();

  const { data, isLoading, error } = useSWR(
    '/programs',
    async () => {
      const resp = await apiService.program.getPrograms();
      return resp.data;
    },
    { shouldRetryOnError: false }
  );

  const [programs, setPrograms] = useState<DtoProgram[]>([]);

  useEffect(() => {
    if (!data) return;
    const initial = getValues('featureSettings.programAccess.programIds');
    if (!initial || initial.length === 0) return;

    const allPrograms = [
      ...(data.activePrograms ?? []),
      ...(data.inactivePrograms ?? []),
    ];
    const initialPrograms = [];
    for (const id of initial) {
      const program = allPrograms.find((p) => p.id === id);
      if (program) initialPrograms.push(program);
    }
    setPrograms(initialPrograms);
  }, [data, getValues]);

  const handleAdd = (program: DtoProgram) => {
    setPrograms((prev) => {
      if (prev.find((p) => p.id === program.id)) return prev;
      const next = [...prev, program];
      setValue(
        'featureSettings.programAccess.programIds',
        next.map((p) => p.id),
        { shouldDirty: true }
      );
      return next;
    });
  };

  const handleDelete = (program: DtoProgram) => {
    setPrograms((prev) => {
      const next = prev.filter((p) => p.id !== program.id);
      setValue(
        'featureSettings.programAccess.programIds',
        next.map((p) => p.id),
        { shouldDirty: true }
      );
      return next;
    });
  };

  return (
    <div className='w-full'>
      <div className='w-full flex items-center justify-between border-b border-secondary'>
        <div className='font-bold py-4'>Programs</div>
        {!readOnly && !isLoading && (
          <ProgramPicker onSelect={handleAdd} exclude={programs} />
        )}
      </div>

      {isLoading ? (
        <div className='w-full py-6 flex items-center justify-center text-icon-gray'>
          <Loading text='' />
        </div>
      ) : error && !programs ? (
        <div className='w-full py-6 flex items-center justify-center text-red-001'>
          {err2s(error)}
        </div>
      ) : programs.length === 0 ? (
        <div className='w-full py-6 flex items-center justify-center text-icon-gray'>
          No programs selected
        </div>
      ) : (
        <table className='w-full table-fixed border-collapse '>
          <thead>
            <tr>
              <th className='w-2/12 text-left font-bold pl-3 py-2 border-b border-secondary'>
                Name
              </th>
              <th className='w-5/12 text-left font-bold py-2 border-b border-secondary'>
                Id
              </th>
            </tr>
          </thead>
          <tbody>
            {programs.map((program) => {
              return (
                <tr key={program.id} className='hover:bg-dark-gray'>
                  <td className='text-left text-icon-gray py-3 pl-3 pr-6 border-b border-black-001 truncate'>
                    {program.name}
                  </td>
                  <td className='text-left text-icon-gray py-3 pr-3 border-b border-black-001 truncate'>
                    <div className='flex items-center gap-2'>
                      {program.id}
                      <Link
                        to={`/admin/programs/v2/${program.id}/edit`}
                        target='_blank'
                      >
                        <NewWindowIcon />
                      </Link>

                      <button
                        type='button'
                        className='ml-auto text-red-001'
                        onClick={() => handleDelete(program)}
                      >
                        <DeleteIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

const ondGamePlayAccessOptions = Object.values(EnumsOndGamePlayAccess).map(
  (t) => ({
    label: t.toString(),
    value: t,
  })
);

function OndGamePlaySettingsForm(props: { readOnly?: boolean }) {
  const { readOnly } = props;
  const { watch, setValue } = useFormContext<ProductFeatureFormData>();
  const type = watch('featureSettings.ondGamePlay.access');
  const prevType = usePrevious(type);

  useEffect(() => {
    if (prevType && prevType !== type) {
      setValue(
        'featureSettings.ondGamePlay',
        {
          access: type,
          gamePackIds: null,
          gamePackTagIds: null,
        },
        { shouldDirty: true }
      );
    }
  }, [setValue, type, prevType]);

  return (
    <div className='w-full flex flex-col gap-8'>
      <div className='tracking-wide font-medium'>Ond Game Play Settings</div>
      <Controller<ProductFeatureFormData, 'featureSettings.ondGamePlay.access'>
        name='featureSettings.ondGamePlay.access'
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <label>
            <h2 className='text-base font-bold mb-1'>Access</h2>
            <SelectEnum
              options={ondGamePlayAccessOptions}
              selected={field.value}
              onChange={field.onChange}
              invalid={fieldState.invalid}
              disabled={readOnly}
            />
          </label>
        )}
      />
      {type === EnumsOndGamePlayAccess.OndGamePlayAccessGamePackIds && (
        <GamePackTable />
      )}
      {type === EnumsOndGamePlayAccess.OndGamePlayAccessGamePackTagIds && (
        <GamePackTags />
      )}
    </div>
  );
}

function GamePackTable(props: { readOnly?: boolean }): JSX.Element {
  const { readOnly } = props;

  const { getValues, setValue } = useFormContext<ProductFeatureFormData>();
  const [gamePacks, setGamePacks] = useState<GamePack[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    async function run() {
      setIsLoading(true);
      try {
        const initial = getValues('featureSettings.ondGamePlay.gamePackIds');
        if (!initial || initial.length === 0) return;
        const gamePacks = await Promise.all(
          initial.map((id) => apiService.gamePack.getGamePackById(id))
        );
        setGamePacks(gamePacks.map((p) => fromDTOGamePack(p.data.gamePack)));
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    }
    run();
  }, [getValues]);

  const handleAdd = (gamePack: GamePack) => {
    setGamePacks((prev) => {
      if (prev.find((p) => p.id === gamePack.id)) return prev;
      const next = [...prev, gamePack];
      setValue(
        'featureSettings.ondGamePlay.gamePackIds',
        next.map((p) => p.id),
        { shouldDirty: true }
      );
      return next;
    });
  };

  const handleDelete = (gamePack: GamePack) => {
    setGamePacks((prev) => {
      const next = prev.filter((p) => p.id !== gamePack.id);
      setValue(
        'featureSettings.ondGamePlay.gamePackIds',
        next.map((p) => p.id),
        { shouldDirty: true }
      );
      return next;
    });
  };

  return (
    <div className='w-full'>
      <div className='w-full flex items-center justify-between border-b border-secondary'>
        <div className='font-bold py-4'>Game Packs</div>
        {!readOnly && !isLoading && (
          <div className='w-1/4'>
            <GamePackSelect
              placeholder='Add game pack'
              onChange={(gamePack) => {
                if (!gamePack) return;
                handleAdd(gamePack);
              }}
            />
          </div>
        )}
      </div>

      {isLoading ? (
        <div className='w-full py-6 flex items-center justify-center text-icon-gray'>
          <Loading text='' />
        </div>
      ) : error && !gamePacks ? (
        <div className='w-full py-6 flex items-center justify-center text-red-001'>
          {err2s(error)}
        </div>
      ) : gamePacks.length === 0 ? (
        <div className='w-full py-6 flex items-center justify-center text-icon-gray'>
          No game packs selected
        </div>
      ) : (
        <table className='w-full table-fixed border-collapse '>
          <thead>
            <tr>
              <th className='w-2/12 text-left font-bold pl-3 py-2 border-b border-secondary'>
                Name
              </th>
              <th className='w-5/12 text-left font-bold py-2 border-b border-secondary'>
                Id
              </th>
            </tr>
          </thead>
          <tbody>
            {gamePacks.map((gamePack) => {
              return (
                <tr key={gamePack.id} className='hover:bg-dark-gray'>
                  <td className='text-left text-icon-gray py-3 pl-3 pr-6 border-b border-black-001 truncate'>
                    {gamePack.name}
                  </td>
                  <td className='text-left text-icon-gray py-3 pr-3 border-b border-black-001 truncate'>
                    <div className='flex items-center gap-2'>
                      {gamePack.id}
                      <Link
                        to={`/admin/gamepacks/edit/${gamePack.id}`}
                        target='_blank'
                      >
                        <NewWindowIcon />
                      </Link>

                      <button
                        type='button'
                        className='ml-auto text-red-001'
                        onClick={() => handleDelete(gamePack)}
                      >
                        <DeleteIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

function GamePackTags(props: { readOnly?: boolean }): JSX.Element {
  const { readOnly } = props;

  const { getValues, setValue } = useFormContext<ProductFeatureFormData>();
  const [tags, setTags] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    async function run() {
      setIsLoading(true);
      try {
        const initial = getValues('featureSettings.ondGamePlay.gamePackTagIds');
        if (!initial || initial.length === 0) return;
        const tags = await Promise.all(
          initial.map((id) => apiService.tag.getTagBySlug(`${id}`))
        );
        setTags(tags.map((p) => p.data.tag));
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    }
    run();
  }, [getValues]);

  const handleChange = (tags: Tag[]) => {
    setValue(
      'featureSettings.ondGamePlay.gamePackTagIds',
      tags.map((t) => t.id),
      { shouldDirty: true }
    );
    setTags(tags);
  };

  return (
    <div className='w-full'>
      <div className='w-full flex items-center justify-between'>
        <div className='font-bold py-4'>Game Pack Tags</div>
      </div>

      {isLoading ? (
        <div className='w-full py-6 flex items-center justify-center text-icon-gray'>
          <Loading text='' />
        </div>
      ) : error && !tags ? (
        <div className='w-full py-6 flex items-center justify-center text-red-001'>
          {err2s(error)}
        </div>
      ) : (
        <TagPicker
          onChange={handleChange}
          tags={tags}
          creatable={false}
          multi={true}
          placeholder='Add tag'
          disabled={readOnly}
          formatMeta={GamePackEditorUtils.FormatTagOptionMeta}
        />
      )}
    </div>
  );
}

function VenueSeatCapSettingsForm(props: { readOnly?: boolean }) {
  const { readOnly } = props;
  const { register } = useFormContext<ProductFeatureFormData>();

  return (
    <div className='w-full flex flex-col gap-8'>
      <div className='tracking-wide font-medium'>Venue Seat Cap Settings</div>

      <div className='flex justify-between gap-4'>
        <div>
          <h2 className='text-base font-bold'>Seat Cap</h2>
          <h3 className='text-sm mb-1 text-icon-gray'>
            Maximum number of seats per venue. Note: We always reserve a seat
            for the venue owner (coordinator).
          </h3>
        </div>
        <input
          type='number'
          className='w-full h-10 field mb-0'
          defaultValue={3}
          {...register(`featureSettings.venueSeatCap.seatCap`, {
            valueAsNumber: true,
            min: 1,
            required: true,
          })}
          disabled={readOnly}
        />
      </div>
    </div>
  );
}

const ondGameUGCAccessOptions = Object.values(EnumsOndGameUGCAccess).map(
  (t) => ({
    label: t.toString(),
    value: t,
  })
);

function OndGameUGCSettingsForm(props: { readOnly?: boolean }) {
  const { readOnly } = props;

  return (
    <div className='w-full flex flex-col gap-8'>
      <div className='tracking-wide font-medium'>Ond Game UGC Settings</div>
      <Controller<ProductFeatureFormData, 'featureSettings.ondGameUGC.access'>
        name='featureSettings.ondGameUGC.access'
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <label>
            <h2 className='text-base font-bold mb-1'>Access</h2>
            <SelectEnum
              options={ondGameUGCAccessOptions}
              selected={field.value}
              onChange={field.onChange}
              invalid={fieldState.invalid}
              disabled={readOnly}
            />
          </label>
        )}
      />
    </div>
  );
}

function Header(props: {
  title: string;
  error?: unknown;
  disabled?: boolean;
  readOnly?: boolean;
  resetError: () => void;
  onCancel: () => void;
  onSave: () => void;
}) {
  const { error, title, onCancel, onSave, resetError, disabled, readOnly } =
    props;
  const { isSubmitting, isDirty } = useFormState<ProductFeatureFormData>();
  return (
    <header>
      <div className='px-5 h-20 flex items-center justify-between border-b border-secondary'>
        <div className='text-white font-bold text-xl'>{title}</div>
        <div className='flex items-center gap-5'>
          {readOnly ? (
            <button
              type='button'
              onClick={onCancel}
              className='btn-secondary w-33 h-10'
            >
              Close
            </button>
          ) : (
            <>
              <button
                type='button'
                onClick={onCancel}
                disabled={disabled || isSubmitting}
                className='btn-secondary w-33 h-10'
              >
                {isDirty ? 'Cancel' : 'Close'}
              </button>
              <button
                type='button'
                onClick={onSave}
                disabled={!isDirty || disabled || isSubmitting}
                className='btn-primary w-33 h-10 flex items-center justify-center'
              >
                {isSubmitting ? (
                  <Loading text='' imgClassName='w-5 h-5' />
                ) : (
                  <>Save</>
                )}
              </button>
            </>
          )}
        </div>
      </div>
      {error ? (
        <div className='w-full px-5 py-2 text-base flex gap-1 bg-lp-gray-001'>
          <p className='text-red-005'>Error: {err2s(error)}</p>
          <button
            type='button'
            onClick={resetError}
            className='btn self-end text-primary'
          >
            Clear
          </button>
        </div>
      ) : null}
    </header>
  );
}

export function ProductFeatureEditor(props: {
  productId: string;
  initialData?: DtoProductFeature | null;
  onSave: () => Promise<void>;
  onCancel: () => void;
  readOnly?: boolean;
}): JSX.Element {
  const { initialData, productId, onCancel, onSave, readOnly } = props;

  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const formReturned = useForm<ProductFeatureFormData>({
    defaultValues: {
      type: initialData?.type ?? null,
      name: initialData?.name ?? null,
      description: initialData?.description ?? null,
      featureSettings: initialData?.featureSettings ?? null,
    },
  });

  const [error, setError] = useState<unknown | undefined>();

  const handleSave = formReturned.handleSubmit(
    async (data: ProductFeatureFormData) => {
      if (!data.name || !data.type) throw new Error('name and type expected');
      try {
        if (initialData) {
          await apiService.product.updateProductFeature(
            productId,
            initialData.id,
            {
              type: data.type,
              name: data.name,
              description: data.description ?? undefined,
              featureSettings: data.featureSettings ?? undefined,
            }
          );
        } else {
          await apiService.product.createProductFeature(productId, {
            type: data.type,
            name: data.name,
            description: data.description ?? undefined,
            featureSettings: data.featureSettings ?? {},
          });
        }
      } catch (e) {
        setError(e);
        return;
      }
      await onSave();
    }
  );

  const {
    call: onDelete,
    state: { state: deleteState },
  } = useLiveAsyncCall(async () => {
    if (!initialData) return;
    await apiService.product.deleteProductFeature(productId, initialData.id);
  });

  const handleDelete = async () => {
    const response = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>Delete Feature</ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            Are you sure you want to delete this feature? This action cannot be
            undone.
          </ConfirmCancelModalText>
        </div>
      ),
      confirmBtnLabel: 'Delete',
      confirmBtnVariant: 'delete',
    });
    if (response.result !== 'confirmed') return;

    await onDelete();
    await onSave();
  };

  const resetError = () => setError(undefined);
  const title = initialData
    ? readOnly
      ? 'Feature'
      : 'Edit Feature'
    : 'Add Feature';

  return (
    <FormProvider {...formReturned}>
      <div className='w-full h-full min-h-0 text-white flex flex-col'>
        <Header
          onCancel={onCancel}
          onSave={handleSave}
          title={title}
          error={error}
          resetError={resetError}
          disabled={deleteState.isRunning}
          readOnly={readOnly}
        />

        <div className='flex-1 min-h-0 flex flex-col overflow-y-scroll scrollbar'>
          <section className='px-6 pb-6'>
            <div className='pt-4'>
              <ProductFeatureForm readOnly={readOnly} />
            </div>
          </section>

          {initialData && !readOnly && (
            <section className='mt-auto pb-6 w-full flex justify-center'>
              <button
                type='button'
                className='btn text-red-001 text-sm flex items-center gap-1'
                onClick={handleDelete}
                disabled={deleteState.isRunning}
              >
                <DeleteIcon className='w-3 h-3 fill-current' />
                {deleteState.isRunning ? <>Deleting...</> : <>Delete</>}
              </button>
            </section>
          )}
        </div>
      </div>
    </FormProvider>
  );
}
